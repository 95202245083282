<template>
  <v-container class="pa-6">
    <v-row justify="center" class="py-5">
      <v-expansion-panels v-model="expanded" multiple accordion flat>
        <draggable
          :list="panelOptions"
          group="cards"
          :class="
            $vuetify.breakpoint.smAndDown
              ? ''
              : 'row fill-width overflow-x-auto no-wrap'
          "
          class="my-0"
          @end="dragColumn($event)"
        >
          <v-expansion-panel
            expand
            v-model="expanded"
            role="button"
            v-for="(option, index) in panelOptions"
            :key="index"
            class="my-0"
          >
            <v-expansion-panel-header>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                  fill="#757575"
                />
              </svg>
              <span class="subtitle-1 font-weight-medium">{{
                $t(option)
              }}</span>
              <v-btn
                elevation="0"
                fab
                outlined
                width="20"
                height="20"
                v-if="option != 'maps'"
                class="ml-2 text-caption font-weight-regular"
                color="primary"
              >
                {{
                  option === "notes"
                    ? notesCount
                    : option === "imagesAttachments"
                      ? attachmentsCount
                      : 0
                }}</v-btn
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <MapView
                :ticket-details="ticketDetails"
                v-if="option == 'maps'"
              />
              <Notes :ticket-details="ticketDetails" v-if="option == 'notes'" />
              <ImagesAttachments
                :ticket-details="ticketDetails"
                :ticket-attachments="ticketDetails.attachments.attachments"
                :limit-exceeds="(ticketDetails.attachments.uploadedAttachmentsSize > ticketDetails.attachments.attachmentsStorageSize)"
                v-if="option == 'imagesAttachments' && ticketDetails.attachments != undefined"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </draggable>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>
<script>
import Notes from "./Notes.vue";
import MapView from "./MapView.vue";
import ImagesAttachments from "./ImagesAttachments.vue";
import UserDataMixin from "@/mixins/UserData";
import Draggable from "vuedraggable";
export default {
  components: {
    Notes,
    MapView,
    ImagesAttachments,
    Draggable,
  },
  mixins: [UserDataMixin],
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      expanded: [0],
      panelOptions: [],
    };
  },
  created() {
    let panels = [];    
    if (this.userData.options.panelOptions) {
      let userPanelOptions = this.userData.options.panelOptions;
      userPanelOptions.forEach((element) => {
        if (this.checkPermission("viewWorkArea") && element == "maps")
          panels.push("maps");
        if (
          this.checkPermission("ticketAttachments") &&
          element == "imagesAttachments"
        )
          panels.push("imagesAttachments");
        if (this.checkPermission("ticketNotes") && element == "notes")
          panels.push("notes");
      });
      this.panelOptions = panels;
    } else {
      if (this.checkPermission("viewWorkArea")) panels.push("maps");
      if (this.checkPermission("ticketAttachments"))
        panels.push("imagesAttachments");
      if (this.checkPermission("ticketNotes")) panels.push("notes");
      this.panelOptions = panels;
      this.dragColumn();
    }
    if (this.checkPermission("viewWorkArea") && !panels.includes("maps"))
      panels.push("maps");
    if (
      this.checkPermission("ticketAttachments") &&
      !panels.includes("imagesAttachments")
    )
      panels.push("imagesAttachments");
    if (this.checkPermission("ticketNotes") && !panels.includes("notes"))
      panels.push("notes");
    this.panelOptions = panels;
    this.expanded = [this.panelOptions.indexOf("maps")];
  },
  methods: {
    async dragColumn() {
      let options = this.userData.options;
      options.panelOptions = [...this.panelOptions];
      const result = await this.$store.dispatch("updateUser", {
        id: this.userData.user_id,
        options: options,
      });
      const userData = await this.$store.dispatch("getUserDataById");
      if (userData.status !== "error") {
        this.$store.commit("setUserData", userData[0]);
      }
    },
  },
  computed: {
    userData() {
      return this.$store.state.User.user;
    },
    notesCount() {
      return this.ticketDetails.notes ? this.ticketDetails.notes.length : 0;
    },
    attachmentsCount() {
      return this.ticketDetails?.attachments?.attachments
        ? this.ticketDetails?.attachments?.attachments?.length
        : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-expansion-panel {
  border: 1px solid #e0e0e0;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.item-selected-text {
  position: relative;
  right: 30px;
  top: 2px;
}
</style>
