import { render, staticRenderFns } from "./TicketExpensionPanel.vue?vue&type=template&id=4b9ab290&scoped=true&"
import script from "./TicketExpensionPanel.vue?vue&type=script&lang=js&"
export * from "./TicketExpensionPanel.vue?vue&type=script&lang=js&"
import style0 from "./TicketExpensionPanel.vue?vue&type=style&index=0&id=4b9ab290&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9ab290",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VRow})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b9ab290')) {
      api.createRecord('4b9ab290', component.options)
    } else {
      api.reload('4b9ab290', component.options)
    }
    module.hot.accept("./TicketExpensionPanel.vue?vue&type=template&id=4b9ab290&scoped=true&", function () {
      api.rerender('4b9ab290', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workflow/ticket/TicketExpensionPanel.vue"
export default component.exports